import { Box, Heading, Text } from '@stars-ecom/shared-atoms-ui'
import { NewsletterForm, WebsiteContext } from '@stars-ecom/shared-organisms-ui'
import React, { useContext } from 'react'

const NewsletterPage = (props) => {
  const { pageContext } = props
  const { newsletter } = pageContext
  const websiteContext = useContext(WebsiteContext)

  return (
    <Box backgroundColor="#f6f3f1" w="100%">
      <Box
        w={['auto', 'auto', 'auto', '1000px']}
        m="auto"
        pb="20px"
        pl={['16px', '16px', '24px', '0']}
        pr={['16px', '16px', '24px', '0']}>
        <Heading
          as="h1"
          fontFamily={{
            base: 'PT Sans, Arial, sans-serif',
            md: '"PT Sans Narrow","PT Sans",Arial,sans-serif'
          }}
          pt={{ base: '15px', md: '40px' }}
          pb={{ base: '0px', md: '20px' }}
          fontSize={['28px', '28px', '60px', '60px']}
          fontWeight="700">
          {newsletter?.title}
        </Heading>
        <Text
          fontSize={{ base: '14px', md: '18px' }}
          color="#333"
          mt="18px"
          mb="30px"
          fontFamily={websiteContext?.fontFamily}>
          {newsletter?.subtitle}
        </Text>
        <NewsletterForm />
        <Box
          mt="20px"
          mb="10px"
          fontSize="11px"
          fontFamily="PT Sans Narrow"
          color="#5e5e5e"
          align="justify"
          sx={{
            '& a': {
              color: websiteContext?.mainColor,
              '&:hover': {
                textDecoration: 'underline',
                color: websiteContext?.darkColor
              }
            }
          }}
          dangerouslySetInnerHTML={{ __html: websiteContext?.legalMention }}
        />
      </Box>
    </Box>
  )
}

NewsletterPage.pageType = 'newsletter'

export default NewsletterPage
